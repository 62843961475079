import React from 'react';
import {classes} from './CartSummarySkeleton.st.css';
import {Skeleton} from '../../../../../../common/components/Skeleton/Skeleton';
import {SkeletonSection} from '../../../../../../common/components/Skeleton/SkeletonSection/SkeletonSection';
import {SkeletonRow} from '../../../../../../common/components/Skeleton/SkeletonRow/SkeletonRow';
import {SkeletonLine} from '../../../../../../common/components/Skeleton/SkeletonLine/SkeletonLine';
import {CartBodyDataHook} from '../../../CartApp/partials/CartBody';

export const CartSummarySkeleton = () => (
  <Skeleton className={classes.asideSkeleton} dataHook={CartBodyDataHook.CartAsideLoader}>
    <SkeletonSection>
      <SkeletonRow>
        <SkeletonLine size="medium" />
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="large" />
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="medium" />
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="large" />
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="medium" />
        <SkeletonLine size="small" />
      </SkeletonRow>
    </SkeletonSection>
    <div className={classes.divider} />
    <SkeletonSection>
      <SkeletonRow>
        <SkeletonLine size="medium" />
        <SkeletonLine size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLine size="xl" />
      </SkeletonRow>
    </SkeletonSection>
  </Skeleton>
);
